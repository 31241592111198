import React, { useState, useEffect } from 'react';
import './styles.scss';
// import { Icon } from '@iconify/react';
// import closeIcon from '@iconify/icons-iwwa/close';

export default function  Modal(props){

    const [active, setActive] = useState(null);

    useEffect(() => {

        // console.log(props.modalActive);
        ( props.modalActive === true ) ? setActive('active') : setActive(null);

        if (props.modalActive === true) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
          }
          return () => {
            document.body.style.overflowY = 'unset';
            document.body.style.overflowX = 'hidden';
            document.body.style.paddingRight = '0px';
          };

    }, [props]);

    return (
        <div id="modal" className={active}>
            <div id="modalContent" className={props.width}>
                {props.children}
            </div>
        </div>
    );

};