import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import facebook from '../../images/footer/facebook.svg';
import instagram from '../../images/footer/instagram.svg';
import linkedin from '../../images/footer/linkedin.svg';
import spotfy from '../../images/footer/spotfy.svg';
import twitter from '../../images/footer/twitter.svg';
import youtube from '../../images/footer/youtube.svg';
import montebravo from '../../images/footer/montebravo.svg';
import logoFooter from '../../images/footer/logo-footer.svg';
import arrowRight from '../../images/footer/arrow-right.svg'
import { BrowserView } from 'react-device-detect';

export default function Footer(){


    const [modalLGPD, setModalLGPD] = useState(true);
    const [activeLGPD, setActiveLGPD] = useState(null);

    useEffect(()=>{
        if(!window.localStorage.getItem('LGPD')){
            setModalLGPD(true)
            if (modalLGPD === true ) {
                setActiveLGPD('active')
            } else {
                setActiveLGPD(null)
            }
        }
    },[])

    return(
        <footer id="footer" >
            <div id="footer-1">
                <div className="content">
                    <div className="row">
                        <div className="col-12 col-lg-4 links-redes">
                            <a target="_blank" href=" https://www.instagram.com/montebravoinvestimentos/">
                                <img src={instagram} />
                            </a>
                            <a target="_blank" href="https://www.facebook.com/montebravo/">
                                <img src={facebook} />
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/company/monte-bravo/">
                                <img src={linkedin} />
                            </a>
                            <a target="_blank" href="https://www.youtube.com/channel/UC9N5J8SRokbu24Qak5zbpKg">
                                <img src={youtube} />
                            </a>
                            <a target="_blank" href="https://open.spotify.com/show/65cdHai7UB6LkIglXu3mYB?si=Rsd3f54TSWGeOYDoMQcxQw">
                                <img src={spotfy} />
                            </a>
                            <a target="_blank" href="https://twitter.com/montebravo0">
                                <img src={twitter} />
                            </a>
                            <a target="_blank" href="https://montebravo.com.br/">
                                <img src={montebravo} />
                            </a>
                        </div>
                        <div className="col-12 col-lg-4 logo-footer">
                        <a target="_blank"  href="https://montebravo.com.br/">
                                <img src={logoFooter} />
                            </a>
                        </div>
                        <div className="col-12 col-lg-4 links">
                            <BrowserView>
                            <a target="_blank" href="https://montebravo.com.br/" className="link-footer">
                                IR PARA O SITE DA <span>MONTE BRAVO</span> <img src={arrowRight} />
                            </a>
                            <a href="https://www.montebravo.com.br/politica-de-privacidade/" target="_blank" className="link-politicas">
                                Política de privacidade
                            </a>
                            </BrowserView>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div id="footer-2">
                <div className="content">
                    <div className="row">
                        <div className="col-12 ft-div ">
                            <span>Copyright ©2021. Todos os Direitos Reservados</span>
                        </div>
                    </div>
                </div>
            </div>


            <div id="modallgpd" className={activeLGPD}>
                <div>
                    <div id="modal-ini">
                        <p>
                            Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa <a href="https://www.montebravo.com.br/politica-de-privacidade/" target="_blank">Política de Privacidade</a> e, ao continuar navegando, você concorda com estas condições.
                        </p>
                        <div class="div-button text-right">
                            <button onClick={(e) => window.localStorage.setItem('LGPD', false) & setActiveLGPD(false)} className="fechar">PROSSEGUIR</button>
                        </div>
                    </div>
                </div>
            </div>

        </footer>


    );
}
