import React, { useState, useEffect, Component } from 'react';
import Routes from './Routes';
import ReactGA from 'react-ga';

//css
import './Styles/bootstrap/bootstrap.scss'
import './Styles/bootstrap/bootstrap-reboot.scss'
import './Styles/bootstrap/bootstrap-grid.scss'
import './Styles/App.scss'
import './Styles/main-responsive.scss'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

    ReactGA.initialize ('UA-115026382-3');
    ReactGA.pageview(window.location.pathname + window.location.search);


export default class App extends Component {
    

    render() {
        return (
            <Routes />
        );
    }
}