import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../../images/logo.svg'

import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

export default function Header(){

    return(
        <div id="header">
        <BrowserView>
            <div id="header-browser">
                <div className="content">
                    <div className="row">
                        <div className="col-6">
                            <img alt="Logo Monte Bravo" src={logo}></img>
                        </div>

                        <div className="col-6 font-graphie d-flex justify-content-end align-items-center">
                            {/* <AnchorLink className="font-weight-700" href='#sobre'>O Evento</AnchorLink> */}
                            <AnchorLink className="font-weight-700" href='#convidados'>Convidados</AnchorLink>
                            <AnchorLink className="font-weight-700" href='#programacao'>Programação</AnchorLink>
                        </div>
                    </div>
                </div>
            </div>
            
        </BrowserView>


        <MobileView>
        <header id="header-mobile">
            <div className="content">
                <div className="row no-gutters ctr-logo">
                    <div className="logo">
                        <img alt="Logo Monte Bravo" src={logo}></img>
                    </div>
                    
                    <Menu className="menu-mobile" right width={ '750px' }>
                        {/* <a className="font-weight-700" href='http://mbconnections.com.br#sobre'>O Evento</a> */}
                        <a className="font-weight-700" href='http://mbconnections.com.br#convidados'>Convidados</a>
                        <a className="font-weight-700" href='http://mbconnections.com.br#programacao'>Programação</a>
                    </Menu>
                </div>
            </div>
        </header>
        </MobileView>

        </div>
    );
}
