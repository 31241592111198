import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import ScrollToTop from '../src/Scroll'

import Layout from "./Components/Layout/Layout";

import Index from './Pages/Index/Index';
import Sucesso from './Pages/sucesso/Index';


export default function Routes(){

    return(
        <BrowserRouter>
            <ScrollToTop>
                <Switch>
                    <Layout>
                        <Route path="/" exact component={Index} />
                    </Layout>
                </Switch>
            </ScrollToTop>
        </BrowserRouter>
    );
}
