import React, { useState, useEffect, Component } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import { useSpring, animated } from 'react-spring'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga';

import Modal  from '../../Components/Modal/index';

/* IMG BANNER */ 
import icoPlus from '../../images/header/header-ico-plus.svg'
import icoArrow from '../../images/header/header-ico-arrow.svg'
import icolosang from '../../images/header/header-ico-losang.svg'
import calendario from '../../images/header/calendario.svg'
import camera from '../../images/header/camera.svg'
import quanto from '../../images/header/quanto.svg'
import imageHeader from '../../images/header/image-header.svg'
import logoBanner from '../../images/header/logo-header.svg'
import arrowBack from '../../images/header/arrow-back.svg'

/* IMG SOBRE */ 
import backgroundSobre from '../../images/sobre/background2.svg'
import imagemSobre1 from '../../images/sobre/imagem-1.png'
import imagemSobre2 from '../../images/sobre/imagem-2.png'
import backgroundMobileSobre from '../../images/sobre/background-mobile.jpg'

/* PROGRAMAÇÃO */
import arrowRightProg from '../../images/programacao/arrow-right.svg'
import arrowLeftProg from '../../images/programacao/arrow-left.svg'
import arrowRightDisableProg from '../../images/programacao/arrow-right-disable.svg'
import arrowLeftDisableProg from '../../images/programacao/arrow-left-disable.svg'

/* IMG CONVIDADOS */
import MaisConvidados from '../../images/convidados/mais-convidados.jpg'

import CVBruno from '../../images/convidados/bruno.jpg'
import CVBrunoMD from '../../images/convidados/modal/bruno-franciulli-modal.jpg'

import CVIsabela from '../../images/convidados/isabela-nunes.jpg'
import CVIsabelaMD from '../../images/convidados/modal/isabella-nunes-modal.jpg'

import CVLuiz from '../../images/convidados/luiz-felipe.jpg'
import CVLuizMD from '../../images/convidados/modal/luiz-filipe-modal.jpg'

import CVRomero from '../../images/convidados/romero.jpg'
import CVRomeroMD from '../../images/convidados/modal/romero-rodrigues-modal.jpg'

import CVStefano from '../../images/convidados/stefano.jpg'
import CVStefanoMD from '../../images/convidados/modal/stefano-sergole-modal.jpg'

import CVleandro from '../../images/convidados/leandro.jpg'
import CVleandroMD from '../../images/convidados/modal/leandro-modal.jpg'

import CVmarco from '../../images/convidados/marco-aurelio.jpg'
import CVmarcoMD from '../../images/convidados/modal/marco-aurelio-modal.jpg'

import CVaretha from '../../images/convidados/aretha.jpg'
import CVarethaMD from '../../images/convidados/modal/aretha-modal.jpg'

import CVcristiano from '../../images/convidados/cristiano.jpg'
import CVcristianoMD from '../../images/convidados/modal/cristiano-modal.jpg'

import CVbrunoSoares from '../../images/convidados/bruno-soares.jpg'
import CVbrunoSoaresMD from '../../images/convidados/modal/bruno-soares-modal.jpg'

import CVricardo from '../../images/convidados/ricardo.jpg'
import CVricardoMD from '../../images/convidados/modal/ricardo-modal.jpg'

import CVbrunoMadruga from '../../images/convidados/bruno-madruga.jpg'
import CVbrunoMadrugaMD from '../../images/convidados/modal/bruno-madruga-modal.jpg'

import CVsonia from '../../images/convidados/sonia.jpg'
import CVsoniaMD from '../../images/convidados/modal/sonia-modal.jpg'

import CVfranchini from '../../images/convidados/franchini.jpg'
import CVfranchiniMD from '../../images/convidados/modal/franchini-modal.jpg'

import CVrebeca from '../../images/convidados/rebeca.jpg'
import CVrebecaMD from '../../images/convidados/modal/rebeca-modal.jpg'

import CVigorpiquet from '../../images/convidados/igor-piquet.jpg'
import CVigorpiquetMD from '../../images/convidados/modal/igor-piquet-modal.jpg'

import CVbrunolucchi from '../../images/convidados/bruno-lucchi.jpg'
import CVbrunolucchiMD from '../../images/convidados/modal/bruno-lucchi-modal.jpg'

import CVgiane from '../../images/convidados/giane.jpg'
import CVgianeMD from '../../images/convidados/modal/giane-modal.jpg'

import CVpietro from '../../images/convidados/pietro.jpg'
import CVpietroMD from '../../images/convidados/modal/pietro-modal.jpg'

import CVcaiopaes from '../../images/convidados/caio-paes.jpg'
import CVcaiopaesMD from '../../images/convidados/modal/caio-paes-modal.jpg'

import CVjoaoluizbraga from '../../images/convidados/joao-luiz-braga.jpg'
import CVjoaoluizbragaMD from '../../images/convidados/modal/joao-luiz-braga-modal.jpg'



/* IMG PATROCINADORES e APOIO */
import patrocinador1 from '../../images/patrocinadores/logo-patrocinador.svg'
import patrocinador2 from '../../images/patrocinadores/logo-patrocinador2.svg'
import patrocinador3 from '../../images/patrocinadores/logo-patrocinador3.svg'
import patrocinador4 from '../../images/patrocinadores/logo-patrocinador4.svg'

import apoio1 from '../../images/patrocinadores/logo-endeavor 1.svg'
import apoio2 from '../../images/patrocinadores/logo-xtree.svg'
import apoio3 from '../../images/patrocinadores/logo-svnl.png'
import apoio4 from '../../images/patrocinadores/TramaNacena.png'

import './Index.scss'
import { BrowserView, MobileView } from 'react-device-detect';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`
const trans2 = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0)`

const participantes = [
    {
        nome: 'Leandro Karnal',
        desc: 'Doutor em História Social, Professor da UNICAMP e Apresentador da CNN Brasil.',
        texto: 'Leandro Karnal é historiador, doutor em História Social pela USP. Professor da UNICAMP, é reconhecido em todo o país como palestrante, intelectual, formador de opinião e, mais recentemente, apresentador de TV, youtuber e influenciador digital. Autor de diversos best sellers, entre os quais,“Crer ou Não Crer” e “O Dilema do Porco Espinho”. Apresentador do programa CNN Brasil Tonight, colunista fixo do jornal O Estado de S. Paulo e conselheiro da Orquestra Sinfônica do Estado de São Paulo (OSESP), Leandro Karnal também é presença constante no Café Filosófico CPFL. Suas mídias sociais alcançam mais de 6 milhões de seguidores e seu canal no YouTube “Prazer, Karnal” tem 1 milhão de inscritos.',
        foto: CVleandro,
        fotoMD: CVleandroMD,
    },
    {
        nome: 'Isabella Nunes',
        desc: 'Diretora Executiva da J.P. Morgan Asset Management.',
        texto: 'Com uma larga experiência na indústria de investimentos, Isabella Nunes é responsável pelo relacionamento com os investidores em todos os segmentos no Brasil, incluindo private bank, familly office, plataformas de investimentos, clientes institucionais e corporativos. Anteriormente, foi responsável pela área de clientes do Pátria Investimentos, Goldman Sachs Asset Management e AllianceBernstein. É graduada em Administração de Empresas pela Fundação Armando Álvares penteado – FAAP, possui MBA em Finanças pelo Insper, SP e possui a certificação CFP® (Certified Financial Planner).',
        foto: CVIsabela,
        fotoMD: CVIsabelaMD,
    },
    {
        nome: 'Rebeca Nevares',
        desc: 'Sócia e Head de Marketing da Monte Bravo.',
        texto: "Trabalha há mais de 14 anos no mercado financeiro. Atuou \n também como porta-voz, dando palestras e entrevistas nos principais \n canais de comunicação do Brasil, falando sobre liderança feminina e \n investimentos. Apaixonada por pessoas e pela causa da mulher,\n resolveu empreender em um propósito maior, a Ella's Investimentos,\n  onde buscou desbravar o mercado financeiro pela ótica feminina. \n  Se uniu a Monte Bravo como sócia e head de Marketing para levar \n esse olhar da diversidade. Colunista da Suno e da Investing Brasil. \n Mãe de uma menina, a Mariah, quer deixar um legado \n para as futuras gerações.",
        foto: CVrebeca,
        fotoMD: CVrebecaMD,
    },
    {
        nome: 'Stefano Sergole',
        desc: 'Diretor de Distribuição da Hashdex.',
        texto: 'Stefano Sergole é sócio e Diretor de Distribuição da Hashdex. Ele possui vasta experiência em produtos e serviços financeiros, com quase quase uma década em instituições financeiras líderes como BTG Pactual, Citibank e Banco Safra, onde passou por diversas áreas como Wealth Management, Trading, Câmbio e Operações Offshore. Stefano também tem profundo conhecimento do mercado de tecnologia, tendo liderado times de venda na ServiceNow, SAP e Oracle. Ele é formado em Administração de Empresas pela PUC-SP.',
        foto: CVStefano,
        fotoMD: CVStefanoMD,
    },
    {
        nome: 'Marco Aurélio Barreto',
        desc: 'Sócio da Tauá Partners.',
        texto: 'Possui mais de 25 anos de experiência em mercado de capitais e \n reestruturações societária e financeira. Atuou no Banco \n Patrimônio / Salomon Brothers e JP Morgan, foi sócio na Íntegra \n  Associados, IVIX e RK Partners. Fundou a Tauá em 2017. Marco \n  é MBA pelo MIT e possui graduação na FGV.',
        foto: CVmarco,
        fotoMD: CVmarcoMD,
    },
    {
        nome: 'Bruno Madruga',
        desc: 'Sócio e Head de Renda Variável da Monte Bravo.',
        texto: "Iniciou sua trajetória no mercado financeiro em 2009, como Investidor Pessoa Física, passando a atuar como Assessor Financeiro profissionalmente em 2010. Em 2012, foi convidado a integrar a Monte Bravo, com foco no atendimento dos clientes no mercado de Renda Variável. Formado em Administração pela Universidade Franciscana (UFN), possui Certificação Ancord, PQO Bovespa Operacional e MBA em Ações e Stock Picking.",
        foto: CVbrunoMadruga,
        fotoMD: CVbrunoMadrugaMD,
    },
    {
        nome: 'Romero Rodrigues',
        desc: 'Sócio-diretor da Redpoint e fundador do Buscapé.',
        texto: 'Romero Rodrigues é sócio-diretor da Redpoint eventures, o primeiro fundo Brasileiro de VC criado em sociedade com firmas do Vale do Silício, co-fundador do Cubo, com R$ 1.2 BI AUM e 48 investimentos. Romero é também co-fundador e ex CEO do Buscapé Company, um serviço de comparação de preços, que foi vendido em 2009 ao grupo sul-africano Naspers por 374 milhões de dólares, a maior saída de uma startup até aquela data.',
        foto: CVRomero,
        fotoMD: CVRomeroMD,
    },
    {
        nome: 'Aretha Duarte',
        desc: 'Embaixadora da Monte Bravo, montanhista, ativista ambiental \n e empreendedora social.',
        texto: 'Aretha Duarte se tornou a primeira mulher negra latino-americana da História a chegar no cume do Everest no dia 23 de maio de 2021. Aretha é montanhista, ativista ambiental e empreendedora social. Antes do topo do mundo, já esteve no Pequeno Alpamayo (Bolívia), Campo Base do Everest (Nepal), Vulcões (Equador), Kilimanjaro (Tanzânia), Elbrus (Rússia), Monte Roraima (Venezuela) e no topo do Aconcágua cinco vezes (Argentina).',
        foto: CVaretha,
        fotoMD: CVarethaMD,
    },

]

const participantes2 = [
    {
        nome: 'Cristiano Castro',
        desc: 'Diretor da BlackRock Brasil.',
        texto: 'Cristiano Castro é Diretor da BlackRock Brasil responsável pelo relacionamento com os clientes de Wealth Management, englobando bancos, private banks, plataformas de investimentos, corretoras e family offices. Possui graduação em Administração pelo Universidade de Brasília(UnB), pós-graduação em Gestão de Risco e Produtos Financeiros pela FIA/USP e Mestrado em Economia pelo Insper.',
        foto: CVcristiano,
        fotoMD: CVcristianoMD,
    },
    {
        nome: 'Giane Guerra',
        desc: 'Colunista de economia na \n Rádio Gaúcha, GZH, Zero \n Hora e RBS TV.',
        texto: "Giane Guerra é colunista de economia na Rádio Gaúcha, \n GZH, Zero Hora e RBS TV. Formada em Jornalismo e Direito e \n estudante de economia. Cursou MBA em Finanças na FIA/SP, \n especialização em Business Strategy pela University of La Verne, \n nos Estados Unidos. Já participou de cursos internacionais de \n jornalismo econômico na Thomson Reuters e de diversas coberturas \n de eventos nacionais e internacionais, como NRF (NY), Conferência \n Mundial de Petroquímica no Texas, Feira de Hannover, Rio Oil and \n Gás e Rio+20. Também recebeu diversos prêmios e homenagens pela \n atuação no jornalismo econômico, como o +Admirados da Imprensa \n de Economia, Negócios e Finanças.",
        foto: CVgiane,
        fotoMD: CVgianeMD,
    },
    {
        nome: 'Sônia Silverio',
        desc: 'Sócia e Head Corporate da Monte Bravo.',
        texto: "Possui 35 anos de experiência no mercado financeiro, \n com grande conhecimento em Officer Comercial no segmento \n  Corporate  do Itaú BBA e Banker Ultra no Itaú Private Bank. Faz parte \n do time da Monte Bravo Corporate há 4 anos. Formada em \n Administração com mestrado em Estratégia de Negócio \nna PUC e conselheira IBGC / CFP®️.",
        foto: CVsonia,
        fotoMD: CVsoniaMD,
    },
    {
        nome: 'Caio Paes de Andrade',
        desc: 'Secretário do Ministério da Economia.',
        texto: "Secretário Especial de Desburocratização, Gestão e Governo Digital do Ministério da Economia. \n \n Empreendedor serial em tecnologia de informação,  mercado imobiliário e agronegócio, Caio Mario Paes de Andrade tem formação em Comunicação Social pela Universidade Paulista, pós-graduação em Administração e Gestão pela Harvard University e Mestre em Administração de Empresas pela Duke University. É fundador e conselheiro do Instituto Fazer Acontecer. Em 2019 passou da iniciativa privada para a área pública com a missão de ajudar a Transformação Digital do Brasil. Foi Presidente do SERPRO até agosto de 2020, quando assumiu a Secretária Especial de Desburocratização, Gestão e Governo Digital.",
        foto: CVcaiopaes,
        fotoMD: CVcaiopaesMD,
    },
    // {
    //     nome: 'Bruno Soares',
    //     desc: 'Embaixador da Monte Bravo, tenista, empresário e atleta olímpico.',
    //     texto: 'Com mais de 30 títulos na carreira, Bruno Soares é um dos maiores nomes do tênis brasileiro, N° 2 do mundo e irá disputar as Olimpíadas de 2021. Ele começou a jogar tênis com 5 anos, quando morava no Iraque. Ajuda projetos sociais indiretamente e é embaixador do Wimbelemdon, além do projeto de apoio aos jovens tenistas na Fly Sports (bolsas de estudos). Gosta de conviver com pessoas, conhecer outras culturas e de estar com família.',
    //     foto: CVbrunoSoares,
    //     fotoMD: CVbrunoSoaresMD,
    // },
    {
        nome: 'Rodrigo Franchini',
        desc: 'Sócio e Head de Relações Institucionais da Monte Bravo.',
        texto: "Possui 15 anos de sólida experiência como Gestor de \nPatrimônio no HSBC, Sales Manager, regional de Wealth \n Management, especialista em investimentos no Bradesco, além de \n passagens em outras instituições como Safra e Itaú. Acumula diversas \n certificações como CPA 10, CPA 20, CEA e Ancord. Terminou seu \n MBA com foco em Bancos e instituições na FGV. Se juntou a \n Monte Bravo em 2018 para mudar a maneira do brasileiro investir. \n Pai de um menino, entende o quão importante é ter \n uma educação financeira para as próximas gerações.",
        foto: CVfranchini,
        fotoMD: CVfranchiniMD,
    },
    {
        nome: 'Bruno Lucchi',
        desc: 'Diretor Técnico da CNA.',
        texto: "Bruno Lucchi, zootecnista formado pela UFV, com especialização em produção de ruminantes pela ESALQ/USP e mestrado em Agronegócios pela Unb. Atua na Confederação da Agricultura e Pecuária do Brasil desde 2009, sendo o últimos seis anos como Diretor Técnico da CNA.",
        foto: CVbrunolucchi,
        fotoMD: CVbrunolucchiMD,
    },
    {
        nome: 'Bruno Franciulli',
        desc: 'Sócio da RBR Asset Management e Head da área de Relação com Investidores.',
        texto: 'Sócio da RBR Asset Management desde 2014 e Head da \n área de Relação com Investidores. Anteriormente, foi sócio da \n área de Wealth Management da Advis Investimentos \n por mais de 4 anos. Formado em Administração de \n Empresas pela Fundação Getúlio Vargas – EAESP.',
        foto: CVBruno,
        fotoMD: CVBrunoMD,
    },
    {
        nome: 'Pietro Fittipaldi',
        desc: 'Piloto da Fórmula 1 e da \n Indy Car.',
        texto: 'Pietro Fittipaldi é piloto da Fórmula 1 e da Indy Car. Pilotar \n já estava no sangue, já que é neto do bicampeão da \n Fórmula 1, Emerson Fittipaldi. \n \n Pietro disputa campeonatos automobilísticos desde os 4 anos de \n idade. Em 2018, sofreu um acidente e quebrou as 2 pernas mas, \n com persistência e disciplina, voltou ao esporte 2 meses \n depois do acidente, com as pernas ainda quebradas. \n Em 2020, correu seu primeiro Grande Prêmio de F1 \n e acaba de correr as 500 Milhas de Indianápolis.',
        foto: CVpietro,
        fotoMD: CVpietroMD,
    },
    {
        nome: 'João Luiz Braga',
        desc: 'Sócio fundador e Analista de Investimentos na Encore.',
        texto: 'João Luiz Braga é sócio fundador e analista de investimentos na Encore. Iniciou sua carreira na GP Investimentos como analista. A partir de 2005 foi analista, sócio e gestor da Credit Suisse Hedging-Griffo, onde trabalhou como analista do Fundo Verde e acumulou a função de gestor dos fundos de renda variável da casa. A partir de 2015 foi sócio do Grupo XP e gestor dos fundos de renda variável da XP Asset. É engenheiro eletricista formado pela Escola Politécnica da USP, possui MBA pelo Insper e é CFA Charterholder.',
        foto: CVjoaoluizbraga,
        fotoMD: CVjoaoluizbragaMD,
    },
    {
        nome: 'Luiz Filipe Araújo',
        desc: 'Especialista em Real Estate da Vinci Partners.',
        texto: 'Atualmente responsável pela estratégia de ativos financeiros. Enquanto membro da Vinci Real Estate, desde 2014, trabalhou na aquisição de imóveis compreendendo mais de R$ 1 bilhão em investimento total e R$ 300 milhões em operações de alavancagem. Atuou também na gestão e monitoramento dos investimentos imobiliários da gestora nos segmentos de shopping, logística e escritório. Luiz Filipe possui graduação em Engenharia de Produção pela Universidade Federal do Rio de Janeiro – UFRJ.',
        foto: CVLuiz,
        fotoMD: CVLuizMD,
    },
    {
        nome: 'Igor Piquet',
        desc: 'Managing Director da Endeavor.',
        texto: 'Igor Piquet é Managing Director do Scale-Up Ventures e \n Venture Partner do Endeavor Catalyst, fundos de investimento \n  de impacto da Endeavor, a maior rede de empreendedores \n  de alto crescimento do mundo.',
        foto: CVigorpiquet,
        fotoMD: CVigorpiquetMD,
    },
    {
        nome: 'Ricardo Lopes',
        desc: 'Sócio e Head do Private da Monte Bravo.',
        texto: "Formado e pós-graduado em Administração de Empresas \n pela FGV. Com 25 anos de experiência no mercado de Wealth \n Management  em grandes bancos internacionais, se uniu a \n Monte Bravo em 2020 como sócio responsável pelo Private \n Banking e Produtos e Serviços. Conselheiro da Planejar desde 2017, \n tem como propósito difundir a importância do Planejamento \n Financeiro para toda a sociedade Brasileira.",
        foto: CVricardo,
        fotoMD: CVricardoMD,
    },
]

export default function Index() {

    const [modalActive, setModalActive] = useState(false)
    const [modalName, setModalName] = useState(false)
    const [modalTexto, setModalTexto] = useState(false)
    const [modalFoto, setModalFoto] = useState(false)
    const [tabs, setTabs] = useState('d1')
    const [video, setVideo] = useState('d1')
    const [forms, setForms] = useState('select-forms')

    const [viewConvidados, setViewConvidados] = useState('close')

    const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

    /* Formulario Clientes */
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')
    const [checkbox, setCheckbox] = useState('')

    const [radio, setRadio] = useState('')

    const [patrimonio, setPatrimonio] = useState('')

    const [modalSucesso, setModalSucesso] = useState(false)

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://d335luupugsy2.cloudfront.net/js/loader-scripts/ff34c759-b09d-4c20-85aa-ae5cc78318ad-loader.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const handleSubmit = (e) => {
        ReactGA.event ({ 
            category: 'Cadastro',
            action: 'MB Connections',
            label: 'Sucesso'
        });

        if (typeof window !== "undefined") {
            if (window.fbq != null) { 
                window.fbq('track', 'CompleteRegistration')
            }
        }

        alert(' No dia do evento receba o link para assistir!', <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3316145&conversionId=4407969&fmt=gif"/>)

    }

    return (
        <div id="home">
            <BrowserView>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
            </BrowserView>
            <div id="banner" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
                <animated.div class="bg-grade" style={{ transform: props.xy.interpolate(trans1) }} />
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="icons-topo d-flex align-items-center justify-content-center">
                                <ScrollAnimation className="fadeIn" duration="2">
                                    <img alt="Icon Plus" src={icoPlus} />
                                </ScrollAnimation>
                                <ScrollAnimation className="fadeIn" duration="3">
                                    <img alt="Icon Arrow" src={icoArrow} />
                                </ScrollAnimation>
                                <ScrollAnimation className="fadeIn" duration="4">
                                    <img alt="Icon Losango" src={icolosang} />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="programacao-box">
                                <div className="dates d-flex align-items-center justify-content-center">
                                    <a href="javascript:;" onClick={() => setVideo('d1')} className={`link-date ${video === 'd1' && 'active'}`}>
                                        21.06
                                    </a>
                                    <a href="javascript:;" onClick={() => setVideo('d2')} className={`link-date ${video === 'd2' && 'active'}`}>
                                        22.06
                                    </a>
                                    <a href="javascript:;" onClick={() => setVideo('d3')} className={`link-date ${video === 'd3' && 'active'}`}>
                                        23.06
                                    </a>
                                    <a href="javascript:;" onClick={() => setVideo('d4')} className={`link-date ${video === 'd4' && 'active'}`}>
                                        24.06
                                    </a>
                                </div>


                                <div id="d1" className={`daily-programation ${video === 'd1' && 'active'}`}>
                                    <iframe width="100%" height="600" src="https://www.youtube.com/embed/pF9XVVCtxvc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>

                                <div id="d2" className={`daily-programation ${video === 'd2' && 'active'}`}>
                                    <iframe width="100%" height="600" src="https://www.youtube.com/embed/8TIFqL4M7nw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                
                                <div id="d3" className={`daily-programation ${video === 'd3' && 'active'}`}>
                                    <iframe width="100%" height="600" src="https://www.youtube.com/embed/KM1dsdqER-A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>

                                <div id="d4" className={`daily-programation ${video === 'd4' && 'active'}`}>
                                    <iframe width="100%" height="600" src="https://www.youtube.com/embed/6yndwGXLBK4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row conteudo-banner d-none">
                        <div className="col-12 col-lg-6 content-banner">
                            <div className="box-content">
                                <BrowserView>
                                    <ScrollAnimation className="fadeIn" duration="1">
                                        <span className="text-dias">
                                            21 a 24 de <br />
                                            junho às 18h
                                        </span>
                                    </ScrollAnimation>
                                </BrowserView>
                                <MobileView>
                                    <span className="text-dias">
                                            21 a 24 de <br />
                                            junho às 18h
                                        </span>
                                </MobileView>
                                <BrowserView>
                                    <h1 className="font-graphie">
                                        <span className="title1 effect">INVESTIMENTOS.</span> 
                                        <span className="title2 effect">NEGÓCIOS.</span>
                                        <span className="title3 effect">INOVAÇÃO.</span>
                                    </h1>
                                </BrowserView>
                                <MobileView>
                                    <h1 className="font-graphie">
                                        <span className="title1">INVESTIMENTOS.</span> 
                                        <span className="title2">NEGÓCIOS.</span>
                                        <span className="title3">INOVAÇÃO.</span>
                                    </h1>
                                </MobileView>
                            </div>
                            <p>                            
                                O evento contará com painéis exclusivos com convidados como Leandro Karnal, 
                                apresentador da CNN Brasil, Isabella Nunes, Diretora Executiva da J.P. Morgan,
                                Romero Rodrigues, fundador do Buscapé e muitos outros.
                            </p>
                        </div>

                        <div className="col-12 col-lg-6 d-none">
                            <div class="banner-right">
                                <div className={`n-client box-form ${forms === 'form-n-client' && 'active'}`}>
                                        {/* <form onSubmit={handleSubmit} name="mb_connections_nao_clientes"> */}
                                        {/* LINK SITE */}
                                        <form onSubmit={handleSubmit} name="mbconnections_form_integrado">
                                        <div>
                                            <span className="titulo">
                                                Faça sua inscrição
                                            </span>
                                        </div>

                                        <div className="row">
                                            <label className="col-6 ipt">
                                                <span>Nome*</span>
                                                <input
                                                onChange={event => setName(event.target.value)}
                                                value={name}
                                                type="text"
                                                name="name"
                                                required />
                                            </label>

                                            <label className="col-6 ipt">
                                                <span>Telefone (com DDD)*</span>
                                                <InputMask 
                                                    maskChar={null}
                                                    required  
                                                    type="tel" 
                                                    minLength={10}
                                                    maxLength="11"
                                                    value={telefone} 
                                                    onChange={event => setTelefone(event.target.value)} 
                                                    name="telefone" 
                                                />
                                            </label>

                                            <label className="col-7 col-lg-8 ipt">
                                                <span>Email*</span>
                                                <input
                                                onChange={event => setEmail(event.target.value)}
                                                value={email}
                                                id="email"
                                                name="email"
                                                type="email" 
                                                required />
                                            </label>

                                            
                                            <div className="col-5 col-lg-4 ipt ipt-change">
                                                <span>Já é cliente?</span>
                                                <div className="row col-12">
                                                    <label className="">
                                                        <input
                                                        onChange={event => setRadio(event.target.value)}
                                                        value="Sim"
                                                        name="cliente"
                                                        type="radio" 
                                                        required /> 
                                                        <p>Sim</p>
                                                    </label>
                                                    <label className="">
                                                        <input
                                                        onChange={event => setRadio(event.target.value)}
                                                        value="Não"
                                                        name="cliente"
                                                        type="radio" 
                                                        required /> 
                                                        <p>Não</p>
                                                    </label>
                                                </div>
                                            </div>
                                            
                                            <label className={`col-12 col-lg-12 ipt ipt-select ${radio === 'Não' && 'active'}`}>
                                                <span>Em que faixa seu patrimônio se encontra?</span>
                                                <select
                                                onChange={event => setPatrimonio(event.target.value)}
                                                value={patrimonio}
                                                name="patrimonio">
                                                    <option value=""> </option>
                                                    <option value="Até R$ 100 mil"> Até R$ 100 mil </option>
                                                    <option value="De R$ 100 mil a R$ 300 mil">De R$ 100 mil a R$ 300 mil</option>
                                                    <option value="De R$ 300 mil a R$ 1 milhão">De R$ 300 mil a R$ 1 milhão</option>
                                                    <option value="De R$ 1 milhão a R$ 5 milhões">De R$ 1 milhão a R$ 5 milhões</option>
                                                    <option value="Acima de R$ 5 milhões">Acima de R$ 5 milhões</option>
                                                </select>
                                            </label>  

                                            <label className="col-12 ckbox">
                                                <input
                                                onChange={event => setCheckbox(event.target.value)}
                                                value="Sim"
                                                name="comunicacao"
                                                type="checkbox"
                                                required />
                                                <span>Eu concordo em receber comunicações.</span>
                                            </label>

                                            {/* <div className="col-6 text-left">
                                                <a  href="javascript:;" onClick={() => setForms('select-forms')} className={`icon-back ${forms === 'select-forms' && 'active'}`}> Voltar</a>
                                            </div> */}
                                            <div className="col-12 d-flex justify-content-center align-center">
                                                <input type="submit" value="Enviar" />
                                            </div>
                                        </div>

                                    </form>

                                    <p className="texto-termos">
                                    Ao continuar você concorda com os <a href="https://www.montebravo.com.br/politica-de-privacidade/" target="_blank">termos de uso</a> e poderá receber conteúdo das marcas no evento. 
                                    Você pode alterar suas permissões a qualquer momento.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row d-none">
                        <div className="col-12">
                            <div className="box-full">
                                <div className="box cl-white">
                                    <img alt="Calendario" src={calendario} />
                                    <span  className="font-graphie">Quando</span>
                                    <p>21 a 24 de junho</p>
                                </div>

                                <div className="box cl-white">
                                    <img alt="Camera" src={camera} />
                                    <span className="font-graphie">Onde</span>
                                    <p>Youtube</p>
                                </div>

                                <div className="box cl-white">
                                    <img alt="Quanto" src={quanto} />
                                    <span className="font-graphie">Quanto</span>
                                    <p>Totalmente Gratuito</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SOBRE */}
            <BrowserView>
            <div id="sobre">
                <div className="content">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                                    <h2>
                                        Conecte os seus <br />
                                        investimentos com o futuro.
                                    </h2>
                                <p className="cl-purple subtitle">
                                    4 dias de lives exclusivas<br />
                                    21 a 24 de junho<br />
                                </p>
                                <p className="font-text text font-graphie">
                                O mb Connections desse ano reuniu especialistas para falar de investimentos de forma disruptiva. <br />
                                Debatemos temas como Portfólio ESG, Criptoativos, Venture Capital, Real State e muito mais.
                                </p>
                                <AnchorLink className="bt bt-pd" href='#programacao'>ver programação</AnchorLink>
                            </ScrollAnimation>
                        </div>


                        <div className="col-12 col-lg-5 images">
                            <div className="bg-icons">
                                <ScrollAnimation animateIn="fadeIn" duration="2" delay={300}>
                                    <img alt="Icon Plus" src={icoPlus} />
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeIn" duration="2" delay={600}>
                                    <img alt="Icon Arrow" src={icoArrow} />
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeIn" duration="2" delay={900}>
                                    <img alt="Icon Losango" src={icolosang} />
                                </ScrollAnimation>
                            </div>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={800}>
                                <img className="bg-sobre" alt="Background" src={backgroundSobre} />
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={500}>
                                <img className="imagem-sobre1" alt="Imagem um" src={imagemSobre1} />
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={600}>
                                <img className="imagem-sobre2" alt="Imagem dois" src={imagemSobre2} />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
            </BrowserView>
            <MobileView>
            <div id="sobre">
                <div className="content">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                                    <h2>
                                        Conecte os seus <br />
                                        investimentos com o futuro.
                                    </h2>
                                <p className="cl-purple subtitle">
                                    4 dias de lives exclusivas<br />
                                    21 a 24 de junho<br />
                                </p>
                                    <p className="font-text text font-graphie">
                                    O mb Connections desse ano reuniu especialistas para falar de investimentos de forma disruptiva. 
                                    Debatemos temas como Portfólio ESG, Criptoativos, Venture Capital, Real State e muito mais.
                                    </p>
                                <AnchorLink className="bt bt-pd" href='#programacao'>ver programação</AnchorLink>
                        </div>


                        <div className="col-12 col-lg-5 images">
                            <img className="img-mobile" alt="Icon Plus" src={backgroundMobileSobre} />
                        </div>
                    </div>
                </div>
            </div>
            </MobileView>


            {/* CONVIDADOS */}
            <BrowserView>
                <div id="convidados">
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={300}>
                        <div className="content">
                            <div className="row">
                                <div className="col-12">
                                    <h3>Convidados</h3>
                                    <p className="font-text font-graphie">
                                    Confira a lista de especialistas que fizeram parte dos 8 painéis nesses 4 dias de evento.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                {participantes.map((row, key) => (
                                    <div className="col-3 d-flex align-items-center justify-content-center flex-column">
                                        <a className="box-image link-box" onClick={(e) => setModalActive(true) & 
                                            setModalName(row.nome) &
                                            setModalTexto(row.texto) &
                                            setModalFoto(row.fotoMD) 
                                        } 
                                            
                                        >
                                            <img src={row.foto} />
                                            <h4>
                                                {row.nome}
                                            </h4>
                                        </a>
                                        <p className="desc-convidado">{row.desc}</p>
                                    </div>
                                ))}
                            </div>

                            <div className="row">
        
                                {participantes2.map((row, key) => (
                                    <div className="col-3 d-flex align-items-center justify-content-center flex-column">
                                        <a className="box-image link-box" onClick={(e) => setModalActive(true) & 
                                            setModalName(row.nome) &
                                            setModalTexto(row.texto) &
                                            setModalFoto(row.fotoMD) 
                                        } 
                                            
                                        >
                                            <img src={row.foto} />
                                            <h4>
                                                {row.nome}
                                            </h4>
                                        </a>
                                        <p className="desc-convidado">{row.desc}</p>
                                    </div>
                                ))}

                                <div className="col-3 d-none align-items-center justify-content-center flex-column">
                                    <div>
                                        <a className="box-image" >
                                            <img src={MaisConvidados} />
                                        </a>
                                        <p className="desc-convidado"></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ScrollAnimation>
                </div>
            </BrowserView>
            <MobileView>
                <div id="convidados">
                    <div className="content">
                        <div className="row">
                            <div className="col-12">
                                <h3>Convidados</h3>
                                <p className="font-text font-graphie">
                                Confira a lista de especialistas que fizeram parte dos 8 painéis nesses 4 dias de evento.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            {participantes.map((row, key) => (
                                <div className="col-6 d-flex align-items-center justify-content-center flex-column">
                                    <a className="box-image link-box" onClick={(e) => setModalActive(true) & 
                                        setModalName(row.nome) &
                                        setModalTexto(row.texto) &
                                        setModalFoto(row.fotoMD)
                                    } 
                                        
                                    >
                                        <img src={row.foto} />
                                        <h4>
                                            {row.nome}
                                        </h4>
                                    </a>
                                    <p className="desc-convidado">{row.desc}</p>
                                </div>
                            ))}

                        </div>

                        <div className={`row box-vermais ${viewConvidados === 'open' && 'active'}`}>
                            {participantes2.map((row, key) => (
                                <div className="col-6 d-flex align-items-center justify-content-center flex-column">
                                    <a className="box-image link-box" onClick={(e) => setModalActive(true) & 
                                        setModalName(row.nome) &
                                        setModalTexto(row.texto) &
                                        setModalFoto(row.fotoMD) 
                                    } 
                                        
                                    >
                                        <img src={row.foto} />
                                        <h4>
                                            {row.nome}
                                        </h4>
                                    </a>
                                    <p className="desc-convidado">{row.desc}</p>
                                </div>
                            ))}

                            <div className="col-6 d-none align-items-center justify-content-center flex-column">
                                <div>
                                    <a className="box-image" >
                                        <img src={MaisConvidados} />
                                    </a>
                                    <p className="desc-convidado"></p>
                                </div>
                            </div>
                        </div>

                        <div className="row buttons">
                            <div className="col-12 text-center">

                                <a onClick={() => setViewConvidados('open')} className={`bt-vermais ${viewConvidados === 'open' && 'inactive'}`}>
                                    Ver mais convidados
                                </a>

                                <AnchorLink href="#convidados" onClick={() => setViewConvidados('close')} className={`bt-vermais ${viewConvidados === 'close' && 'inactive'}`}>
                                    Ver menos convidados
                                </AnchorLink>

                            </div>
                        </div>

                        <div className="row d-none">
                            <div className="col-12">
                                <div className="full-button text-center">
                                    <AnchorLink className="bt bt-pd" href='#home'>Inscreva-se</AnchorLink>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </MobileView>


            <div id="programacao">
                <div className="content">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3>Programação</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="programacao-box">
                                <div className="dates d-flex align-items-center justify-content-center">
                                    <a href="javascript:;"
                                        onClick={() => setTabs('d1')}
                                        className={`link-date ${tabs === 'd1' && 'active'}`}
                                    >
                                        21.06
                                    </a>
                                    <a href="javascript:;" onClick={() => setTabs('d2')}
                                        className={`link-date ${tabs === 'd2' && 'active'}`}
                                    >
                                        22.06
                                    </a>
                                    <a href="javascript:;" onClick={() => setTabs('d3')}
                                        className={`link-date ${tabs === 'd3' && 'active'}`}
                                    >
                                        23.06
                                    </a>
                                    <a href="javascript:;" onClick={() => setTabs('d4')}
                                        className={`link-date ${tabs === 'd4' && 'active'}`}
                                    >
                                        24.06
                                    </a>
                                </div>


                                <div id="d1" className={`daily-programation ${tabs === 'd1' && 'active'}`}>

                                    <a className="arrow-left ard">
                                        <img src={arrowLeftDisableProg} />
                                    </a>
                                    <a onClick={() => setTabs('d2')} className={`arrow-right ${tabs === 'd2' && 'active'}`}>
                                        <img src={arrowRightProg} />
                                    </a>

                                    <div className="resume">
                                        <span className="date">
                                            18h | <b> Painel 01 - Como construir um portfólio ESG?</b>
                                        </span>
                                        <span className="palestrante">
                                            Isabella Nunes & Cristiano Castro
                                        </span>
                                        <span className="mediador">
                                            Mediação: Rodrigo Franchini
                                        </span>
                                        <span className="text">
                                            Para abrir o evento, falaremos um pouco do cenário ESG, que ganha cada vez mais seu espaço no Brasil, e entender como aplicá-los de maneira assertiva no portfólio, com a participação de Isabella Nunes, Diretora Executiva, J.P. Morgan Asset Management e Cristiano Castro, Diretor da BlackRock Brasil.
                                        </span>
                                    </div>
                                    <div className="resume">
                                        <span className="date">
                                            19h | <b> Painel 02 - Por que os criptoativos são essenciais para a sua carteira?</b>
                                        </span>
                                        <span className="palestrante">
                                            Stefano Sergole
                                        </span>
                                        <span className="mediador">
                                            Mediação:  Bruno Madruga
                                        </span>
                                        <span className="text">
                                            Neste debate, vamos entender ao lado de Stefano Sergole, sócio e Diretor de Distribuição da Hashdex, como os criptoativos deixaram de ser apenas uma moda e se tornaram essenciais para a diversificação do seu portfólio.
                                        </span>
                                    </div>
                                    
                                </div>

                                <div id="d2" className={`daily-programation ${tabs === 'd2' && 'active'}`}>

                                    <a onClick={() => setTabs('d1')} className={`arrow-left ${tabs === 'd1' && 'active'}`}>
                                        <img src={arrowLeftProg} />
                                    </a>
                                    <a onClick={() => setTabs('d3')} className={`arrow-right ${tabs === 'd3' && 'active'}`}>
                                        <img src={arrowRightProg} />
                                    </a>

                                    <div className="resume">
                                        <span className="date">
                                            18h | <b> Painel 01 - VENTURE CAPITAL: INVESTINDO EM EMPRESAS DISRUPTIVAS</b>
                                        </span>
                                        <span className="palestrante">
                                            Romero Rodrigues & Igor Piquet
                                        </span>
                                        <span className="mediador">
                                            Mediação: Rodrigo Franchini
                                        </span>
                                        <span className="text">
                                            Entenda quais os riscos e benefícios de investir em empresas inovadoras numa conversa com Romero Rodrigues, Sócio-diretor da Redpoint.
                                        </span>
                                    </div>
                                    <div className="resume">
                                        <span className="date">
                                            19h | <b> Painel 02 - REAL ESTATE: APLICANDO A ECONOMIA REAL EM SEU PORTFÓLIO</b>
                                        </span>
                                        <span className="palestrante">
                                            Luiz Filipe Araújo & Bruno Franciulli
                                        </span>
                                        <span className="mediador">
                                            Mediação: Ricardo Lopes 
                                        </span>
                                        <span className="text">
                                            Vamos debater ao lado de Luiz Filipe Araújo, responsável pela estratégia de ativos financeiros na Vinci, a principal casa de Real Estate no Brasil, e Bruno Franciulli, Sócio da RBR Asset Management e Head da área de Relação com Investidores, como investir na economia real e como alocar esse tipo de ativo em sua carteira.
                                        </span>
                                    </div>
                                    
                                </div>
                                
                                <div id="d3" className={`daily-programation ${tabs === 'd3' && 'active'}`}>

                                    <a onClick={() => setTabs('d2')} className={`arrow-left ${tabs === 'd2' && 'active'}`}>
                                        <img src={arrowLeftProg} />
                                    </a>
                                    <a onClick={() => setTabs('d4')} className={`arrow-right ${tabs === 'd4' && 'active'}`}>
                                        <img src={arrowRightProg} />
                                    </a>

                                    <div className="resume">
                                        <span className="date">
                                            18h | <b> Painel 01 - AGRO É POP: COMO O BRASIL MANTÉM SEU CRESCIMENTO E CONSTÂNCIA NO SETOR?</b>
                                        </span>
                                        <span className="palestrante">
                                            Bruno Lucchi
                                        </span>
                                        <span className="mediador">
                                            Mediação: Rodrigo Franchini 
                                        </span>
                                        <span className="text">
                                            Neste painel, vamos entender como o Brasil se mantém estável e em constante crescimento no setor de agronegócio, com uma convidada exclusiva.
                                        </span>
                                    </div>
                                    <div className="resume">
                                        <span className="date">
                                            19h | <b> Painel 02 -  A IMERSÃO DAS EMPRESAS NO MERCADO DE CAPITAIS</b>
                                        </span>
                                        <span className="palestrante">
                                            Marco Aurélio Barreto 
                                        </span>
                                        <span className="mediador">
                                            Mediação: Sônia Silverio
                                        </span>
                                        <span className="text">
                                            Entenda como desbancarizar a sua empresa aproveitando as oportunidades do mercado de capitais ao lado de Marco Aurélio Barreto, Sócio da Tauá Partners.
                                        </span>
                                    </div>
                                    
                                </div>

                                <div id="d4" className={`daily-programation ${tabs === 'd4' && 'active'}`}>

                                    <a onClick={() => setTabs('d3')} className={`arrow-left ${tabs === 'd3' && 'active'}`}>
                                        <img src={arrowLeftProg} />
                                    </a>
                                    <a className="arrow-right ard">
                                        <img src={arrowRightDisableProg} />
                                    </a>

                                    <div className="resume">
                                        <span className="date">
                                            18h | <b> Painel 01 -  OPEN BANKING: ENTENDA SEUS BENEFÍCIOS E FACILIDADES</b>
                                        </span>
                                        <span className="palestrante">
                                            Caio Paes de Andrade & João Luiz Braga
                                        </span>
                                        <span className="mediador">
                                            Mediação: Rodrigo Franchini 
                                        </span>
                                        <span className="text">
                                            Neste painel, vamos debater com quem participou da criação do Open Banking e entender quais as facilidades que ele traz para o mundo dos investimentos. 
                                        </span>
                                    </div>
                                    <div className="resume">
                                        <span className="date">
                                            19h | <b> Painel 02 - Como os desafios nos ensinam a ter resiliência</b>
                                        </span>
                                        <span className="palestrante">
                                            Aretha Duarte, Pietro Fittipaldi & Leandro Karnal
                                        </span>
                                        <span className="mediador">
                                            Mediação: Rebeca Nevares
                                        </span>
                                        <span className="text">
                                            Encerrando o MB Connections, conversaremos com a nossa embaixadora Aretha Duarte, 1ª mulher negra e latino-americana a escalar o Everest, Caio Paes de Andrade, Secretário Especial de Desburocratização, Gestão e Governo Digital do Ministério da Economia e também teremos a participação de Leandro Karnal, historiador, professor e apresentador da CNN, que junto ao embaixadores, conversará sobre resiliência e qual a sua importância para os investidores.
                                        </span>
                                    </div>

                                    </div>


                            </div>
                        </div>
                    </div>

                    <div className="row d-none">
                        <div className="col-12">
                            <div className="full-button text-center">
                            <AnchorLink className="bt bt-pd" href='#home'>Inscreva-se</AnchorLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="patrocinadores">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <h3>Patrocinadores</h3>
                        </div>
                        <div className="col-12">
                            <div className="patrocinadores-images">
                                <img src={patrocinador1} />
                                <img src={patrocinador2} />
                                <img src={patrocinador3} />
                                <img src={patrocinador4} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="apoio">
                <div className="content">
                    <div className="row">
                        <div className="col-12 text-center">
                            <span>Apoio:</span>
                        </div>
                        <div className="col-12">
                            <div className="apoio-images">
                                <img src={apoio1} />
                                <img src={apoio2} />
                                <img className="logosvnl" src={apoio3} />
                                <img className="trama" src={apoio4} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal modalActive={modalActive}>
                <div className="row form">
                    <div className="col-12">
                        <div className="box-modal-convidado">
                        <button onClick={(e) => setModalActive(false)} className="close-modal">X</button>
                            <div className="row">
                                <div className="col-12 col-lg-5">
                                    <div className="content-left">
                                        <img src={modalFoto} />
                                        <p>{modalName}</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7 ">
                                    <div className="content-right">
                                    <p>{modalTexto}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal modalSucesso={modalSucesso}>
                <div className="row form">
                    <div className="col-12">
                        <div className="box-modal-convidado">
                            <button onClick={(e) => setModalSucesso(false)} className="close-modal">X</button>
                            <div className="row">
                                <div className="col-12 col-lg-5">
                                    <div className="content-left">
                                        aaaa
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
}
